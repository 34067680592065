<div class="registration-container">
    @if (isPlatformWeb) {
        <registration-header [brandName]="brandName">
        </registration-header>
    }
    <div class="registration-wrapper" [ngClass]="{ 'shared-offer': sharedOffer }">
        <div class="reg-box-container" [ngClass]="{'register': registerIdle}" >
            @if (!sharedOffer && loginAttempt) {
                <div class="{{'reg-form-column login-context-options ' + brandName.toLowerCase()}}" >
                    <div class="panel-header">
                        <h2>{{'GENERAL.LOGIN.FIRST_TIME' | translate}}</h2>
                    </div>
                    <div class="context-options">
                        <div class="login-context-option register-technician" (click)="openTechnicianRegistrationForm()">
                            <div class="icon-container"></div>
                            <div class="text-container">
                                <p>
                                    {{'GENERAL.LOGIN.CONTEXT_MENU.REGISTER_NOW_1' | translate}}
                                    <a>{{ 'GENERAL.LOGIN.CONTEXT_MENU.REGISTER_NOW_2' | translate}}</a>
                                    {{'GENERAL.LOGIN.CONTEXT_MENU.REGISTER_NOW_3' | translate}}</p>
                            </div>
                        </div>
                        <div class="login-context-option register-mro" (click)="openMroContactWindow()">
                            <div class="icon-container"></div>
                            <div class="text-container">
                                <p>
                                    {{'GENERAL.LOGIN.CONTEXT_MENU.REGISTER_NOW_COMPANY_1' | translate}}
                                    <a>{{ 'GENERAL.LOGIN.CONTEXT_MENU.REGISTER_NOW_COMPANY_2' | translate}}</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div [class]="'reg-form-column ' + brandName.toLowerCase()" [ngClass]="{'register': registerIdle, 'login': loginAttempt}">
                @if (sharedOffer && loginAttempt) {
                    <div class="go-back-container">
                        <staffnow-registration-go-back
                            *ngIf="sharedOffer"
                            [onClick]="resetFormCallback"
                        ></staffnow-registration-go-back>
                        <span>{{'GENERAL.BACK_TO_REGISTER' | translate}}</span>
                    </div>
                }
                <div class="reg-form-content">
                    <staff-loader
                        *ngIf="registerLoading"
                        [customClass]="'splash-screen-loader'"
                    ></staff-loader>
                    <ng-container *ngIf="loginAttempt">
                        <div [class]="'panel-header ' + brandName.toLowerCase()">
                            <h1>{{'GENERAL.LOGIN.HEADER' | translate}}</h1>
                            <div class="logo"></div>
                        </div>
                        <staffnow-login-form
                            [privacyPolicyUrl]="privacyPolicyUrl"
                            [termsAndConditionsUrl]="termsAndConditionsUrl"
                        >
                        </staffnow-login-form>
                    </ng-container>
                    <ng-container *ngIf="registerSuccessful">
                        <success-animation *ngIf="isEntityTechnician">
                            <ng-container message>
                                <div class="important-note text-center">
                                    {{ 'REGISTRATION.VERIFY_EMAIL' | translate}}
                                </div>
                                <br />
                                <span [innerHTML]="'REGISTRATION.VERIFY_EMAIL.ACTIVATION_LINK' | translate: {brandName}">
                                </span>
                                <div class="separate-top" [innerHTML]="'REGISTRATION.VERIFY_EMAIL.IMPORTANT_NOTE' | translate">
                                </div>
                                <br />
                                <div class="important-note text-center">
                                    <b>{{ 'REGISTRATION.VERIFY_EMAIL.DISREGARD_NOTE' | translate }}</b>
                                </div>
                                <br />
                            </ng-container>
                        </success-animation>
                    </ng-container>
                    <ng-container *ngIf="registerIdle">
                        <technician-registration-form
                            [brandName]="brandName"
                            [privacyPolicyLink]="privacyPolicyLink"
                            [termsAndConditionsLink]="termsAndConditionsLink"
                            (submit)="submitTechnicianRegistrationForm($event)"
                        >
                        </technician-registration-form>
                        <div class="divider">
                            <div class="line"></div>
                            <div class="circle"></div>
                            <div class="line"></div>
                        </div>
                    </ng-container>

                    <div
                        class="login-container m-3"
                        *ngIf="registerIdle || registerSuccessful"
                    >
                        <p
                            *ngIf="registerIdle"
                            class="text-center font-weight-bold registration-font-size"
                        >
                            {{ 'REGISTRATION.ALREADY_HAVE_ACCOUNT' | translate }}
                        </p>

                        <general-button
                            (onClick)="login()"
                            class="mt-3 login-btn"
                            [text]="'GENERAL.LOGIN' | translate"
                        ></general-button>
                    </div>

                    <ng-container *ngIf="registerError">
                        <fail-animation
                            [message]="errorMessage | translate"
                        ></fail-animation>
                        <div style="text-align: center">
                            <general-button
                                (onClick)="resetForm()"
                                class="reset-btn"
                                [text]="'GENERAL.RESET' | translate"
                            ></general-button>
                            <div>
                                <span>
                                    {{
                                        'REGISTRATION.FORGOT_YOUR_PASSWORD' | translate
                                    }}
                                </span>
                                <general-button
                                    (onClick)="goToResetPassword()"
                                    class="reset-btn"
                                    [text]="'REGISTRATION.REQUEST_NEW_PASSWORD' | translate"
                                    color="secondary"
                                ></general-button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            @if (showOffer) {
                    <div class="job-offer-container">
                        <div class="job-offer" *ngIf="sharedOffer">
                            <staffnow-job-offer-info
                                [offer]="sharedOffer"
                                [isSharedOfferDto]="true"
                                [displayOfferLocation]="true"
                            >
                                <ng-container *ngIf="isStaffnowBrand() && isFixedPriceOffer()" additional-content>
                                    <span class="additional-message">
                                        {{ 'REGISTRATION.ADDITIONAL_MESSAGE' | translate }}
                                        <ng-container
                                            [ngTemplateOutlet]="contactEmail"
                                            [ngTemplateOutletContext]="{ email:infoEmail }">
                                        </ng-container>
                                    </span>
                                </ng-container>
                            </staffnow-job-offer-info>
                        </div>
                    </div>
            }
        </div>
    </div>
    <ng-template #contactEmail let-email='email'>
        <a
            class="contact-mail"
            [href]="'mailto:' + email"
        >
            {{ email }}
        </a>
    </ng-template>

    <ng-template #contextButtons>
        <div class="context-options">
            <div class="login-context-option register-technician" (click)="openTechnicianRegistrationForm()">
                <div class="icon-container"></div>
                <div class="text-container">
                    <p>
                        {{'GENERAL.LOGIN.CONTEXT_MENU.REGISTER_NOW_1' | translate}}
                        <a>{{ 'GENERAL.LOGIN.CONTEXT_MENU.REGISTER_NOW_2' | translate}}</a>
                        {{'GENERAL.LOGIN.CONTEXT_MENU.REGISTER_NOW_3' | translate}}</p>
                </div>
            </div>
            @if (loginAttempt) {
                <div class="login-context-option register-mro" (click)="openMroContactWindow()">
                    <div class="icon-container"></div>
                    <div class="text-container">
                        <p>
                            {{'GENERAL.LOGIN.CONTEXT_MENU.REGISTER_NOW_COMPANY_1' | translate}}
                            <a>{{ 'GENERAL.LOGIN.CONTEXT_MENU.REGISTER_NOW_COMPANY_2' | translate}}</a>.</p>
                    </div>
                </div>
            }
        </div>
    </ng-template>

    @if (isPlatformWeb) {
        <div [class]="'registration-footer ' + brandName.toLowerCase()">
            <div class="buttons-container">
                @if (brandName === BrandName.StaffNow) {
                    <a [href]="environment.legalNotice"  target=”_blank”>{{ 'GENERAL.LEGAL_NOTICE' | translate }}</a>
                }
                <a [href]="environment.privacyPolicyUrl" target=”_blank”>{{ 'GENERAL.PRIVACY_POLICY' | translate }}</a>
                <a [href]="environment.termsAndConditionsUrl" target=”_blank”>{{ 'GENERAL.T&C' | translate }}</a>
            </div>
        </div>
    }
</div>
